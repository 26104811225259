<template>
  <div id="newsStoryContent">
    <p id="Text1">
      Adventurers,
      <br>
      <br>
      Nexus Naomi, here with the latest information, direct from
      <router-link to="/community/news-network/s/314423">Nexus Tower</router-link>!
      I am pleased to report that at the current rate of construction, Nexus Tower is projected to open its doors on <b>June 1st!</b>
      In just one short week, you will be able to enter the new Faction Headquarters and enjoy all of the exciting new adventures Nexus Tower has to offer!
      <br>
    </p>
    <center>
      <img style="width: 440px; height: 261px;" class="rounded" src="@/assets/news-network/nexus-tower-1.jpg">
    </center>
    <br>
    <b>Meet the legendary Faction Leaders!</b>
    <br>
    Come visit my holostation when you first land in Nexus Tower!
    I will guide you to meet your legendary
    <router-link to="/community/news-network/s/316558">Faction Leader</router-link>
    for the first time!
    <br>
    <br>
    <b>Over a dozen new Vendors have set up shop in Nexus Tower!</b>
    <br>
    Be sure to bring all of your coins and Faction Tokens with you to Nexus Tower.
    Nexus Tower is the place to go for all of your shopping needs with over a dozen new Vendors with exciting new wares like
    <router-link to="/community/news-network/s/315479">Valiant Weapons</router-link>!
    <br>
    <br>
    <center>
      <img style="width: 440px; height: 261px;" class="rounded" src="@/assets/news-network/valiant-weapons.jpg">
    </center>
    <br>
    <b>Vaults and Property Safes!</b>
    <br>
    If you ever find that your Backpack is feeling too heavy, try storing extra items in the
    <router-link to="/community/news-network/s/315046">Vault</router-link>, located in the Sentinel area.
    <br>
    <br>
    <center>
      <img style="width: 440px; height: 261px;" class="rounded" src="@/assets/news-network/vault.jpg">
    </center>
    <br>
    <b>Faction Pets!</b>
    <br>Prove your Faction Loyalty to your Faction Leader to learn the secrets of these long-lost
    <router-link to="/community/news-network/s/316558">Faction companions</router-link>!
    <br>
    <br>
    <center>
      <img style="width: 220px; height: 131px;" class="rounded" src="@/assets/news-network/faction-pet-1.jpg">
      &nbsp;
      <img style="width: 220px; height: 131px;" class="rounded" src="@/assets/news-network/faction-pet-2.jpg">
    </center>
    <br>
    <center>
      <img style="width: 220px; height: 131px;" class="rounded" src="@/assets/news-network/faction-pet-3.jpg">
      &nbsp;
      <img style="width: 220px; height: 131px;" class="rounded" src="@/assets/news-network/faction-pet-4.jpg">
    </center>
    <br>
    <b>Nexus Tower is the launching point to Starbase 3001, LEGO Club and Crux Prime! </b>
    <br>
    Aside from protecting the Imagination Nexus and creating the new Faction Headquarters, Nexus Tower also serves as the new launching point to Starbase 3001, LEGO Club and Crux Prime!
    Existing launch pads to these worlds have been disassembled and rebuilt inside Nexus Tower.
    <br>
    <br>
    Dr. Overbuild strongly suggests that before Nexus Tower opens, all Minifigures should reconvene in Nimbus Station, next to Kurt Tussle.
    That way when Nexus Tower opens, you will be able to fly to Nexus Tower right away!
    <br>
    <br>
    Dr. Overbuild and the other Faction Leaders have worked very hard to complete Nexus Tower – the largest LEGO structure ever.
    Now you have the most difficult part: deciding what to do first inside Nexus Tower on June 1st!
    <br>
    <br>
    Nexus Naomi
    <br>
    <br>
    Discuss this article on the <a href="/messageboards/3791573">message boards</a>!<p></p>
  </div>
</template>
